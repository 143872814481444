import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import SEO from "../components/SEO"
import Layout from "../components/Layout"
import Content, { HTMLContent } from "../components/Content"

export const TermsOfUseTemplate = ({ content, contentComponent }) => {
  const PostContent = contentComponent || Content

  return <PostContent content={content} />
}

TermsOfUseTemplate.propTypes = {
  content: PropTypes.node.isRequired,
}

const TermsOfUse = ({ data, path }) => {
  const { markdownRemark } = data

  return (
    <Layout loadMap={false}>
      <SEO
        path={path}
        title="Terms of Use - EstatesPlus - Wills, Probate & Letters of Administration. Save up to 90% on legal fees"
        description="Terms of Use   These Terms of Use (“Terms”) govern your use of this website (“Site”) and form a binding contractual agreement between you, the user of the Site and us, MKI Legal Pty Ltd and Legal Check Pty Ltd both of care of MKI Legal, Level 4, 200 Adelaide Terrace, East Perth, WA, Australia."
      />
      <div className="container section-gap">
        <TermsOfUseTemplate
          contentComponent={HTMLContent}
          content={markdownRemark.html}
        />
      </div>
    </Layout>
  )
}

TermsOfUse.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default TermsOfUse

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "terms-of-use" } }) {
      html
    }
  }
`
